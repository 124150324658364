<template>
  <div class="wrapper">
    <Header :goodsName="goodsName" :headerData="{name: undefined}" />
    <GoodsDetails :goodsInfo="goodsInfo" :reltedData="reltedData" @jump="handleJump" />
    <Footer />
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import GoodsDetails from "../../components/GoodsDetails";
import { get_goods_details, IMAGE_URL, post_product_list } from "../../api";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Header,
    Footer,
    GoodsDetails,
  },
  data() {
    return {
      goodsInfo: {},
      reltedData: [],
      goodsName: "",
    };
  },
  mounted() {
    this.goodsData(this.$route.query.id);
    this.getReltedList(this.$route.query.id, this.$route.query.shopid);
  },
  methods: {
    handleJump(id) {
      this.$router.push({
        path: "/goods_details",
        query: { id },
      });
      this.goodsInfo = {}
      this.reltedData = []
      this.goodsName = ''
      this.goodsData(id);
      this.getReltedList(this.$route.query.id, this.$route.query.shopid);
    },
    async getReltedList(id, shop_id) {
      const {
        data: {
          data: { data },
        },
      } = await post_product_list({ shop_id, id, page: 1, limit: 4 });
      this.reltedData = data.map((item) => ({
        ...item,
        image: IMAGE_URL + item.image,
      }));
    },
    async goodsData(id) {
      const {
        data: { data },
      } = await get_goods_details({ id });
      data.image = IMAGE_URL + data.image;
      data.images = data.images.split(",").map((item) => IMAGE_URL + item);
      this.goodsInfo = JSON.parse(JSON.stringify(data));
      this.goodsName = data.name;
    },
  },
});
</script>

<style lang="less" scoped>
.wrapper {
  min-height: 100vw;
  min-width: 1600px;
  background: #f1f6f4;

  .home-btn {
    margin-top: 50px;
    width: 100%;
    height: 150px;
    background: url(../../assets/image/backhomeBG.jpg) no-repeat;
    background-size: 100% 100%;
    padding: 0 16.95%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
      width: 150px;
      // height: 80px;
    }
  }
}
</style>
