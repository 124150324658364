<template>
  <div class="wrap">
    <div class="goods-desc">
      <div class="img">
        <img :src="goodsInfo.image" alt="" />
        <div class="image-group" v-if="goodsInfo.images && goodsInfo.images.length >= 2">
          <a-image-preview-group>
            <a-image style="
                position: absolute;
                top: 0;
                left: 0;
                width: 388px;
                height: 388px;
                opacity: 0;
              " v-for="item of goodsInfo.images" :key="item" :src="item" />
          </a-image-preview-group>
        </div>
      </div>

      <div class="content">
        <div class="rt">
          <span class="title">{{ goodsInfo.name }}</span>
          <span class="brief">{{ goodsInfo.keywords }}</span>
        </div>
        <div class="rb" v-html="goodsInfo.description"></div>
      </div>
    </div>
    <div class="goods-details">
      <div class="title">
        <img src="../../assets/image/xiangqing.png" alt="" />
      </div>
      <div class="gd-content" v-html="goodsInfo.intro"></div>
    </div>
    <div class="relted">
      <div class="title">
        <img src="../../assets/image/xiangguan.png" alt="" />
      </div>
      <div class="goods-list-box">
        <div class="goods-list-item" v-for="item of reltedData" :key="item.id">
          <div class="img-box" @click="$emit('jump', item.id)">
            <img :src="item.image" alt="" />
          </div>
          <div class="goods-desc">
            <span class="desc-title">{{ item.name }}</span>
            <img @click="$emit('jump', item.id)" src="../../assets/image/youjiantou.png" alt="" class="icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    goodsInfo: Object,
    reltedData: Array,
  },
})
</script>

<style lang="less" scoped>
.wrap {
  // padding: 0 15%;
  max-width: 1240px;
  margin: 0 auto;
  .relted {
    .title {
      margin: 80px 0 -30px 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .goods-list-box {
      // width: 66%;
      //   height: 800px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      flex-wrap: wrap;

      .goods-list-item {
        margin-top: 80px;
        // flex: 0 0 23%;
        width: 23%;
        // height: 300px;
        &:last-child:nth-child(4n - 1) {
          margin-right: calc(24% + 4% / 3);
        }
        &:last-child:nth-child(4n - 2) {
          margin-right: calc(48% + 8% / 3);
        }
        -moz-box-shadow: 0px 2px 5px #ddd;
        -webkit-box-shadow: 0px 2px 5px #ddd;
        box-shadow: 0px 2px 5px #ddd;
        transition: all 0.5s;
        &:hover {
          -moz-box-shadow: 0px 0px 15px #bbb;
          -webkit-box-shadow: 0px 0px 15px #bbb;
          box-shadow: 0px 0px 15px #bbb;
        }
        .goods-desc {
          margin: 0 !important;
          background: #fff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px;
          .icon {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            cursor: pointer;
          }
        }
        .img-box {
          //   max-width: 290px;
          //   max-height: 290px;
          overflow: hidden;
          cursor: pointer;
          padding: 10px 10px 0 10px;
          background: #fff;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.3s;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
  .goods-details {
    .gd-content {
      background: #fff;
      padding: 50px;
      margin-bottom: 100px;
      .text {
        font-size: 16px;
        line-height: 30px;
        color: #666;
      }
      .top {
        color: #323232;
        display: block;
        font-size: 22px;

        &::after {
          content: '';
          width: 30px;
          display: block;
          margin: 10px 0 30px 0;
          border: 1px solid #01b868;
        }
      }
      img {
        display: block;
        margin: 50px auto;
        width: 75%;
      }
    }
    .title {
      margin: 80px 0 50px 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .goods-desc {
    margin-top: 50px;
    width: 100%;
    // border: 2px solid #c9e8d9;
    // border-style: dashed solid;
    background: url('../../assets/image/border.png') no-repeat;
    background-size: 100% 100%;
    padding: 50px;
    display: flex;

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 50px;
      .rt {
        width: 100%;
        padding-bottom: 5px;
        border-bottom: 1px solid #d5dbd9;
        .title {
          font-size: 25px;
          color: #333;
          display: block;
        }
        .brief {
          margin-top: 15px;
          display: block;
          font-size: 16px;
          color: #999;
        }
      }
      .rb {
        color: #333;
        font-size: 18px;
        margin-top: 30px;
      }
    }
    .img {
      width: 388px;
      height: 388px;
      position: relative;
      .image-group {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
      }
      img {
        width: 388px;
        height: 388px;
      }
    }
  }
}
</style>
